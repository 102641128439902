const PROJECTS = [
  {
    id: 1,
    name: "aster reste",
    nickname: "aster",
    shortDescription: "restaurant",
    description:
      "Aster reste is a real restaurant (coming soon) based out of France. I used Ruby on Rails as an exercise in familiarizing myself with CRUD principles, and to quickly build a backend that allows the site owner to create and manage their own blog. Includes bilingual i18n integration.",
    image: "../images/aster-mockup-mini.png",
    imageSm: "../images/aster-mockup-800.png",
    imageMd: "../images/aster-mockup-1000.png",
    imageLg: "../images/aster-mockup-2000.png",
    cardImgSm: "../images/aster-mini-670.png",
    cardImgMd: "../images/aster-mini-800.png",
    cardImgLg: "../images/aster-mini-1300.png",
    skills: ["rails", "javascript", "css"],
    cardBackground: "linear-gradient(94deg, #767e11, #b4b48b)",
    url: "https://salty-depths-22739.herokuapp.com/",
    github: "https://github.com/justjohnd/restaurant",
  },
  {
    id: 2,
    name: "veggit: recipe editor and saver",
    nickname: "veggit",
    shortDescription: "recipe app",
    description:
      "My first attempt at a MERN stack-based project, veggit is a recipe app that lets you write and edit original recipes, as well as upload recipes (via the Spoonacular API) directly from other sites. Full user authentication has been incorporated on the backend.",
    image: "../images/veggit-mockup.png",
    imageSm: "../images/veggit-mockup-800.png",
    imageMd: "../images/veggit-mockup-1000.png",
    imageLg: "../images/veggit-mockup-2000.png",
    cardImgSm: "../images/veggit-mini-670.png",
    cardImgMd: "../images/veggit-mini-800.png",
    cardImgLg: "../images/veggit-mini-1300.png",
    skills: ["react", "node", "mongodb", "express"],
    cardBackground: "linear-gradient(94deg, #27c214, #b7deb3)",
    url: "https://veggit.onrender.com/",
    github: "https://github.com/justjohnd/wayou-kitchen",
  },
  {
    id: 3,
    name: "sound street radio",
    nickname: "soud street",
    shortDescription: "streaming radio",
    description:
      "This multilanguage site was built with an original Wordpress theme, along with Bootstrap. Sound Street Radio streams DJ shows from the UK and Japan.",
    image: "../images/ssr-mockup-mini.png",
    imageSm: "../images/ssr-mockup-800.png",
    imageMd: "../images/ssr-mockup-1000.png",
    imageLg: "../images/ssr-mockup-2000.png",
    cardImgSm: "../images/ssr-mini-670.png",
    cardImgMd: "../images/ssr-mini-800.png",
    cardImgLg: "../images/ssr-mini-1300.png",
    skills: ["wordpress", "gulp", "php", "bootstrap"],
    cardBackground: "linear-gradient(94deg, #FF9000, #ffd6a0)",
    url: "https://ssr990.com/",
    github: "https://github.com/justjohnd/wp-streaming-radio-theme",
  },
];

export default PROJECTS;
